<template>
    <div
        class="message-panel"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
    >
        <div class="white-bg" v-if="isShow">
            <h3 class="g-title" style="margin-top: -20px;">发送消息</h3>
            <div class="send-msg-panel">
                <div class="msg-reply-panel" style="padding-top:50px;">
                    <el-form>
                        <el-form-item label="收信人" v-if="receiveShow == true">
                            <el-autocomplete
                                placeholder="请输入短消息收件人昵称/平台数字ID/入驻名/手机号码"
                                popper-class="my-autocomplete"
                                v-model="state"
                                :fetch-suggestions="querySearch"
                                @select="handleSelect"
                                :trigger-on-focus="false"
                            >
                                <template slot-scope="{ item }">
                                    <span class="addr">{{item.name}}({{item.geek_id}})</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>

                        <el-form-item label="主题">
                            <el-input v-model="form.title" placeholder="输入消息主题"></el-input>
                        </el-form-item>

                        <el-form-item label="内容">
                            <el-input type="textarea" v-model="form.content" placeholder="输入消息内容"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button @click="returnPage">返回</el-button>
                            <el-button @click="send" style="margin-top: 4px;">发送</el-button>
                            <!--如果不帮他支付点券的话 跳转发送成功页面 sendsuccess  如果帮支付就弹窗支付流程-->
                        </el-form-item>
                    </el-form>

                    <el-dialog
                        class="blue-top-border buy-dialog"
                        title="支付"
                        :visible="buyDialog"
                        width="910px"
                        center
                        @close="closeBuy"
                        :close-on-click-modal="false"
                    >
                        <p class="tips">对方查看此信息需要付费，是否帮对方支付？</p>
                        <h5>选择支付方式</h5>
                        <el-radio-group v-model="form.pay_type" class="buy-dialog-radio-group">
                            <el-radio
                                :label="1"
                                v-if="user.surplus && user.surplus > user.message_paymoney"
                            >
                                <div class="buy-group">
                                    <span>
                                        ￥
                                        <strong v-text="user.message_paymoney"></strong>元
                                    </span>
                                    <p>余额支付</p>
                                </div>
                            </el-radio>

                            <el-radio
                                :label="2"
                                v-if="user.points_surplus && user.points_surplus > user.message_paypoint"
                            >
                                <div class="buy-group">
                                    <span>
                                        <strong v-text="user.message_paypoint"></strong>点券
                                    </span>
                                    <p>点券支付</p>
                                </div>
                            </el-radio>

                            <el-radio :label="3">
                                <div class="buy-group">
                                    <span>
                                        ￥
                                        <strong v-text="user.message_paymoney"></strong>元
                                    </span>
                                    <p>
                                        <i class="jk-icon-weixinzhifu"></i>微信支付
                                    </p>
                                </div>
                            </el-radio>

                            <el-radio :label="4">
                                <div class="buy-group">
                                    <span>
                                        ￥
                                        <strong v-text="user.message_paymoney"></strong>元
                                    </span>
                                    <p>
                                        <i class="jk-icon-zhifubaozhifu"></i>支付宝支付
                                    </p>
                                </div>
                            </el-radio>

                            <el-radio :label="5">
                                <div class="buy-group">
                                    <p class="nobuy-tips">
                                        不帮付
                                        <br />直接发送
                                    </p>
                                </div>
                            </el-radio>
                        </el-radio-group>

                        <h6>
                            我的点券余额：
                            <span>{{user.points_surplus?user.points_surplus:0}}点</span>
                            <strong
                                v-if="user.points_surplus==0 || user.points_surplus < user.message_paypoint"
                                @click="gopay"
                            >购买点券</strong>
                            <!--当点券足够的情况下只显示点券数 点券不够时显示余额不足及购买点券-->
                        </h6>

                        <h6>
                            我的账户余额：
                            <span>{{user.surplus?user.surplus:0}}元</span>
                            <strong
                                v-if="user.surplus==0 || user.surplus < user.message_paymoney"
                                @click="gopay"
                            >充值</strong>
                        </h6>

                        <el-checkbox v-model="checked">
                            我已阅读并同意
                            <a href="javascript:void(0)" @click="goArticleContent">《询龙网平台交易规则》</a>
                        </el-checkbox>

                        <el-button @click="pay">发送</el-button>

                        <el-dialog
                            class="blue-top-border"
                            title="发送成功"
                            :visible="SuccessTips"
                            width="400px"
                            center
                            append-to-body
                            @close="cat"
                        >
                            <div class="pointCoupon-buy">
                                <h5>你已经成功发送信息，收到消息后TA可以直接查看消息内容。</h5>
                            </div>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="cat">查看内容</el-button>
                            </span>
                        </el-dialog>
                    </el-dialog>
                </div>
                <div class="contact-panel">
                    <el-card class="box-card" shadow="never">
                        <div slot="header" class="clearfix">
                            <span>最近联系的人</span>
                        </div>
                        <div
                            class="text item"
                            v-for="(item,index) in near"
                            :key="index"
                            @click="selectUser(item)"
                        >{{item.name}}({{item.geek_id}})</div>
                    </el-card>
                    <el-card class="box-card" shadow="never">
                        <div slot="header" class="clearfix">
                            <span>我关注的人</span>
                        </div>
                        <div
                            class="text item"
                            v-for="(item,index) in favor"
                            :key="index"
                            @click="selectUser(item)"
                        >{{item.name}}({{item.geek_id}})</div>
                    </el-card>
                </div>
            </div>

            <!--支付二维码-->
            <el-dialog
                class="blue-top-border pay_dialog_box"
                title="支付"
                :visible="paystatus"
                width="400px"
                center
                append-to-body
                @close="closeQr"
                :close-on-click-modal="false"
            >
                <div
                    class="pointCoupon-buy buy_center"
                    v-if="form.pay_type == 1 || form.pay_type == 2"
                >
                    <el-input v-model="pay_password" type="password" placeholder="请输入支付密码完成支付"></el-input>
                </div>

                <div
                    class="pointCoupon-buy buy_center"
                    v-if="form.pay_type == 3 || form.pay_type == 4"
                >
                    <p>支付金额</p>
                    <p class="price">
                        ￥
                        <strong>{{price}}</strong>（本次）
                    </p>
                    <span class="avatar">
                        <img :src="pay_image" />
                    </span>
                    <div class="guide">
                        请使用{{payTypeText}}扫描
                        <br />二维码完成支付
                    </div>
                </div>

                <span
                    slot="footer"
                    class="dialog-footer"
                    v-if="form.pay_type == 1 || form.pay_type == 2"
                >
                    <el-button @click="doPay">确认支付</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="white-bg" v-else>
            <h3 class="g-title" style="margin-top: -20px;">发送消息</h3>
            <div class="send-msg-panel">
                <div
                    style="width:100%;text-align:center;height:300px;border:1px solid #e6e6e6;background-color: #9f9b9a"
                >
                <p style="padding-top:50px;"><i style="font-size:40px;color: #f3bc66;" class="el-icon-warning-outline"></i></p>
                <p style="padding-top: 5px;font-size: large;font-weight: bolder;">仅入驻的工程极客，或实名认证后的雇主可以发送消息</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "sendMsg",
    metaInfo() {
        return {
            title: "发送消息 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {},
    data() {
        return {
            id: 0,
            receiveShow: true, //收件人label
            buyDialog: false, //支付对话框
            checked: false, //支付协议是否同意checkbox
            SuccessTips: false, //信息发送结果提示框
            state: "", //收件人输入框值
            pay_image: "", //支付二维码
            form: {
                //发送消息表单
                receive_id: 0,
                title: "",
                content: "",
                pay_type: 5
            },
            user: [], //消息支付相关信息
            paystatus: false, //支付二维码对话框
            pay_password: "", //支付密码
            order_sn: "", //订单号
            favor: [], //我关注的人列表
            near: [], //我最近联系的人
            payTypeText: "", //支付方式文字
            timer: "", //检查支付成功定时器
            loading: false,
            price: 0,
            isShow : false
        };
    },
    methods: {
        //20190907修改，发送消息无须支付
        send() {
            var _this = this;
            if (this.form.content == "") {
                this.$message.error("发送内容不能为空");
                return false;
            }

            //创建订单记录发送信息内容
            _this.post(
                "/message/message/send",
                {
                    receive_id: _this.form.receive_id, //收件人id
                    title: _this.form.title, //标题
                    content: _this.form.content, //内容
                    pay_type: _this.form.pay_type //支付方式
                },
                function (res) {
                    if (200 == res.code) {
                        _this.$message.success("消息发送成功");

                        let url = sessionStorage.getItem("currentPageUrl");
                        if (url) {
                            _this.$router.push(url);
                        } else {
                            _this.$router.push("/user/message?id=1");
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //返回
        returnPage() {
            let url = sessionStorage.getItem("currentPageUrl");
            if (url) {
                this.$router.push(url);
            } else {
                this.$router.push("/user/message?id=1&page=1");
            }
        },

        //查询联系人
        querySearch(queryString, cb) {
            this.form.receive_id = "";
            var _this = this;
            _this.post(
                "/message/message/messager_list",
                {
                    keyword: queryString
                },
                res => {
                    if (res.code == 200) {
                        if (res.data.list) {
                            cb(res.data.list);
                        }
                    }
                }
            );
        },
        //选择联系人
        handleSelect(item) {
            this.state = item.name + "(" + item.geek_id + ")"; //给收件人输入框显示昵称和名字
            this.form.receive_id = item.uk;
        },

        // //发送
        // send() {
        //     var _this = this;
        //     if (this.form.content == "") {
        //         this.$message.error("发送内容不能为空");
        //         return false;
        //     }

        //     //获取发送信息支付信息
        //     _this.post(
        //         "/message/message/get_message_info",
        //         {
        //             id: _this.form.receive_id
        //         },
        //         function (res) {
        //             if (res.code == 200) {
        //                 //收件人存在
        //                 if (res.data.has_receive_id == 1) {
        //                     _this.user = res.data; //获取用户余额和支付信息
        //                     _this.buyDialog = true;
        //                 } else {
        //                     _this.$message.error("收件人不存在，请重新选择");
        //                     return false;
        //                 }
        //             } else {
        //                 _this.$message.error("获取用户信息失败");
        //                 return false;
        //             }
        //         }
        //     );
        // },

        //发送
        pay() {
            var _this = this;

            //清空支付二维码
            _this.pay_image = "";

            if (_this.checked == false) {
                _this.$message.error("请认证阅读并同意询龙网平台交易规则");
                return false;
            }
            //创建订单记录发送信息内容
            _this.loading = true;
            _this.post(
                "/message/message/send",
                {
                    receive_id: _this.form.receive_id, //收件人id
                    title: _this.form.title, //标题
                    content: _this.form.content, //内容
                    pay_type: _this.form.pay_type //支付方式
                },
                function (res) {
                    if (200 == res.code) {
                        _this.order_sn = res.data.order_no; //记录订单号
                        _this.id = res.data.id; //记录消息id，用于查看消息

                        if (_this.form.pay_type == 5) {
                            //直接发送不支付
                            _this.loading = false;
                            _this.buyDialog = false; //关闭购买提示框
                            _this.SuccessTips = true; //打开发送成功提示框
                        } else {
                            //点券和余额支付
                            if (
                                _this.form.pay_type == 1 ||
                                _this.form.pay_type == 2
                            ) {
                                _this.loading = false;
                                _this.paystatus = true; //弹出支付密码
                                _this.buyDialog = false; //关闭购买提示框
                                return false;
                            } else {
                                //线上支付
                                _this.doPay();
                            }
                        }

                        //关闭购买对话框
                        _this.buyDialog = false;
                    } else {
                        if (-301 == res.code) {
                            _this.loading = false;
                            _this.$message.error(res.msg);
                            setInterval(() => {
                                this.$router.push(
                                    "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
                                );
                            }, 2000);
                            return false;
                        } else {
                            _this.loading = false;
                            _this.$message.error(res.msg);
                            return false;
                        }
                    }
                }
            );
        },

        //支付
        doPay() {
            var _this = this;

            //支付
            _this.post(
                "/pay/index/pay",
                {
                    order_no: _this.order_sn,
                    password: _this.pay_password
                },
                res => {
                    _this.loading = false;
                    if (res.code == 200) {
                        //点券和余额支付
                        if (
                            _this.form.pay_type == 1 ||
                            _this.form.pay_type == 2
                        ) {
                            _this.paystatus = false;
                            _this.SuccessTips = true;
                        }

                        //微信和支付宝支付
                        if (
                            _this.form.pay_type == 3 ||
                            _this.form.pay_type == 4
                        ) {
                            if (res.data.img) {
                                //显示支付二维码
                                _this.pay_image = res.data.img;

                                //显示支付价格
                                _this.price = res.data.price;

                                //弹出二维码支付框
                                _this.paystatus = true;

                                //检查是否支付成功
                                _this.timer = setInterval(() => {
                                    _this.isPayCheck();
                                }, 2000);
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                        return false;
                    }
                }
            );
        },

        //最近联系人
        nears() {
            var _this = this;
            _this.post("/message/message/near", {}, res => {
                _this.near = res.data.near;
            });
        },

        //我关注的人
        favors() {
            var _this = this;
            _this.post(
                "/user/user/collect_list",
                {
                    type: 1,
                    page: 1,
                    size: 5
                },
                res => {
                    _this.favor = res.data.list;
                }
            );
        },

        //查看消息内容
        cat() {
            this.$router.push("/user/message/detail?id=" + this.id);
        },

        //跳转到用户条例
        goArticleContent() {
            this.$router.push("/protocol/index/3");
        },

        //跳转到充值购买页
        gopay() {
            this.$router.push("/user/wallet/list");
        },

        //选择最近联系人或者收藏的人
        selectUser(item) {
            this.form.receive_id = item.uk;
            this.state = item.name + "(" + item.geek_id + ")"; //给收件人输入框显示昵称和名字
        },

        //关闭支付对话框
        closePayDialog() {
            this.paystatus = false;
        },

        //关闭所有对话框
        closeQr() {
            this.pay_password = "";
            this.paystatus = false; //支付二维码对话框
            clearInterval(this.timer);
        },

        //关闭所有对话框
        closeBuy() {
            this.buyDialog = false; //支付选择对话框
            clearInterval(this.timer);
        },

        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/index/is_pay",
                {
                    order_no: _this.order_sn
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("恭喜你，支付成功！");
                        _this.SuccessTips = true;
                        _this.closeBuy(); //关闭对话框
                        _this.closeQr(); //关闭对话框
                        _this.lookDetail(); //查看消息详情
                    }
                }
            );
        },

        //根据id获取收件人姓名和极客id，用户消息接收人显示
        getGeekNameAndId() {
            let _this = this;
            this.post(
                "/user/user/user_simple_info",
                {
                    uk: this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.state = res.data.name + "(" + res.data.geek_id + ")";
                    }
                }
            );
        }
    },
    created() {
        this.nears();
        this.favors();
        if (this.$route.query.uk) {
            this.form.receive_id = this.$route.query.uk; //获取路由传过来的用户ID
            this.getGeekNameAndId(); //获取用户姓名和id
        }
        let UserInfos = JSON.parse(localStorage.getItem('vuex'));
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (UserInfos.userInfo.attestation && UserInfos.userInfo.attestation.is_pass == 1){
            localStorage.setItem('userInfo',JSON.stringify(UserInfos.userInfo));
            this.isShow = true;
        }
        if (userInfo.attestation && userInfo.attestation.is_pass == 1){
            this.isShow = true;
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/message.less";
.el-card__body {
    .item {
        margin-bottom: 6px !important;
    }
}
.pay_dialog_box .el-dialog__body {
    text-align: center;
    padding: 0 !important;
    .price {
        padding: 0;
        margin: 0;
    }
    .guide {
        margin: 0;
        padding: 0;
        padding-bottom: 30px;
    }
}
.el-form-item {
    .el-button {
        border-radius: 3px !important;
        transition: all ease 0.4s;
        &:hover {
            opacity: 0.9;
            border-color: rgba(88, 125, 220, 0.6);
        }
    }
}
</style>


